import { useContext, useEffect, useRef } from "react";
import Video from "./Video";
import GlobalContext from "../store/contexts/GlobalContext";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import anime from 'animejs';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const Section1 = () => {
    const bgPc = require('../assets/img/sec1-bg-pc.jpg');
    const bgMobile = require('../assets/img/sec1-bg-mobile.jpg');
    const logo18 = require('../assets/img/logo-18+.jpg');
    const character = require('../assets/img/character.png');

    const malePc = require('../assets/img/male-pc.png');
    const femalePc = require('../assets/img/female-pc.png');

    const maleMobile = require('../assets/img/male-mobile.png');
    const femaleMobile = require('../assets/img/female-mobile.png');

    // const
    const decorPc = require('../assets/img/sec1-decor-pc.png');
    const decorMobile = require('../assets/img/sec1-decor-mobile.png');
    const btnPlayVideo = require('../assets/img/btn-playvideo.png');
    const logoName = require('../assets/img/logo-name.png');
    const titlePc = require('../assets/img/title-pc.png');
    const titleMobile = require('../assets/img/title-mobile.png');
    const scroll = require('../assets/img/scroll.png');

    const { showVideo, updateShowVideo } = useContext(GlobalContext);    

    type CustomStyleProperties = {
        [key: string]: string | number;
    };

    const logoRef = useRef<HTMLImageElement>(null);
    const titleRef = useRef<HTMLImageElement>(null);
    const malePcRef = useRef<HTMLImageElement>(null);
    const femalePcRef = useRef<HTMLImageElement>(null);
    const maleMbRef = useRef<HTMLImageElement>(null);
    const femaleMbRef = useRef<HTMLImageElement>(null);
    const btnRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        const logoElement = logoRef.current;
        const titleElement = titleRef.current;
        const malePcElement = malePcRef.current;
        const femalePcElement = femalePcRef.current;
        const maleMbElement = maleMbRef.current;
        const femaleMbElement = femaleMbRef.current;
        const btnElement = btnRef.current;

        if (logoElement) {
            anime({
                targets: logoElement,
                translateY: ['-150px', '0px'],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }

        if (titleElement) {
            anime({
                targets: titleElement,
                translateY: ['150px', '0px'],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }

        if (malePcElement) {
            anime({
                targets: malePcElement,
                translateX: ['-350px', '0px'],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }

        if (femalePcElement) {
            anime({
                targets: femalePcElement,
                translateX: ['150px', '0px'],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }

        if (maleMbElement) {
            anime({
                targets: maleMbElement,
                translateX: ['-350px', '0px'],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }

        if (femaleMbElement) {
            anime({
                targets: femaleMbElement,
                translateX: ['150px', '0px'],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }

        if (btnElement) {
            anime({
                targets: btnElement,
                opacity: [0, 1],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }
    }, []);

    return (
        <div className={`overflow-hidden relative w-full bg-cover bg-center bg-no-repeat aspect-[1284/2017] pc:aspect-[2002/903] bg-[image:var(--bg-mobile-url)] pc:bg-[image:var(--bg-pc-url)]`} style={{'--bg-mobile-url': `url(${String(bgMobile)})`, '--bg-pc-url': `url(${String(bgPc)})`} as CustomStyleProperties} >
            <img src={String(logo18)} alt="" className="mb:hidden tb:hidden absolute top-[9%] left-[6%] w-[7.5%]" />
            {showVideo && <Video />}
            {/* PC - Character */}
            <div className="relative w-full h-full mb:hidden tb:hidden">
                <img ref={malePcRef} src={String(malePc)} alt="" className="absolute -bottom-[12%] right-[19%] h-[115%]" />
                <img ref={femalePcRef} src={String(femalePc)} alt="" className="absolute -bottom-[13%] right-[8%] h-[87%]" />
                <img src={String(decorPc)} alt="Decor" className="absolute bottom-0 w-full" />
            </div>

            {/* Mobile - Character */}
            <div className="relative w-full h-full pc:hidden">
                <img ref={maleMbRef} src={String(maleMobile)} alt="" className="absolute w-[87%] top-[1%] left-0" />
                <img ref={femaleMbRef} src={String(femaleMobile)} alt="" className="absolute bottom-[2%] right-0 w-[84%]" />
                <img src={String(decorMobile)} alt="Decor" className="absolute bottom-0 w-full" />
            </div>

            {/* PC - Navigator */}
            <div className="mb:hidden tb:hidden">
                <img ref={logoRef} src={String(logoName)} alt="Logo Name" className="absolute w-[22%] top-[13%] left-[21%]" />
                <img ref={titleRef} src={String(titlePc)} alt="Logo Name" className="absolute w-[64%] top-[24%] left-0" />
                <button ref={btnRef} className="absolute w-[10%] bg-cover bg-center bg-no-repeat aspect-[368/379] bottom-[17%] left-[27%]" 
                    style={{backgroundImage: `url(${btnPlayVideo})`}} 
                    onClick={() => updateShowVideo?.(true)}
                ></button>
            </div>

            {/* Mobile - Navigator */}
            <div className="pc:hidden absolute bottom-[2%] w-full flex flex-col justify-end items-center">
                <button ref={btnRef} className="w-[28%] bg-cover bg-center bg-no-repeat aspect-[368/379] -mb-[10%]" 
                    style={{backgroundImage: `url(${btnPlayVideo})`}} 
                    onClick={() => updateShowVideo?.(true)}
                ></button>
                <img src={String(logoName)} alt="Logo Name" className="w-[60%] -mb-[12%]" />
                <img src={String(titleMobile)} alt="Logo Name" />
            </div>
            <div className="z-30 absolute left-0 right-0 mx-auto w-full bottom-0 text-center">
                <img src={String(scroll)} alt="" className="inline-block w-[25%] pc:w-[10%] animate-bounce" />
            </div>
        </div>
    )
}

export default Section1