import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import "../assets/css/section5.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import anime from 'animejs';
import { useEffect, useRef } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { fbUrl, zaloOAUrl } from "../config/gameconf";

const Section5 = () => {
    const bgPc = require('../assets/img/sec5-bg-pc.jpg');
    const bgMobile = require('../assets/img/sec5-bg-mobile.jpg');
    const title = require('../assets/img/section5-title.png');
    const weaponName = require('../assets/img/weapon-name.png');
    const contact = require('../assets/img/contact.png');
    const zalo = require('../assets/img/zalo.png');
    const fb = require('../assets/img/fb.png');
    const podium = require('../assets/img/podium-pc.png');
    const podiumMobile = require('../assets/img/podium-mobile.png');
    const male = require('../assets/img/male.gif');
    const female = require('../assets/img/female.gif');
    const dragon = require('../assets/img/dragon.gif');
    const unicorn = require('../assets/img/unicorn.gif');
    const fox = require('../assets/img/fox.gif');

    const targetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const targetElement = targetRef.current;
        
        const handleScroll = () => {
            // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // const elementOffsetTop = targetElement?.offsetTop || 0;
      
            // Define the scroll offset where the animation triggers
            // const scrollOffset = -1800;
      
            // if (scrollTop > elementOffsetTop - scrollOffset) {
                if (targetElement) {
                    const rect = targetElement.getBoundingClientRect();
                    if (rect.top >= 0 && rect.top <= window.innerHeight) {
                    anime({
                        targets: targetElement,
                        opacity: [0, 1],
                        translateX: ['200px', '0px'],
                        duration: 1000,
                        easing: 'easeInOutQuad',
                        });
        
                    // Remove the scroll event listener after the animation has triggered
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup by removing the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    type CustomStyleProperties = {
        [key: string]: string | number;
    };

    return (
        <div className={`relative w-full flex flex-col items-center bg-cover bg-center bg-no-repeat pc:aspect-[2004/1555] aspect-[321/457] pc:bg-[image:var(--bg-pc-url)] bg-[image:var(--bg-mobile-url)]`} style={{'--bg-mobile-url': `url(${String(bgMobile)})`, '--bg-pc-url': `url(${String(bgPc)})`} as CustomStyleProperties} >
            <img src={String(title)} alt="" className="pc:w-[20%] w-2/5 pc:mt-[3%]" />

            <div className="mb:hidden tb:hidden relative w-[83%] mt-[10%] bg-cover bg-center bg-no-repeat aspect-[1531/463]" style={{backgroundImage: `url(${podium})`}}>
                <img src={String(male)} alt="" className="absolute w-[13%] -top-[48%] left-[33%]" />
                <img src={String(female)} alt="" className="absolute w-[15%] -top-[42%] right-[19%]" />
                <img src={String(dragon)} alt="" className="absolute w-[15%] top-[0%] left-[13%]" />
                <img src={String(fox)} alt="" className="absolute w-[20%] top-[27%] left-[42%]" />
                <img src={String(unicorn)} alt="" className="absolute w-[20%] top-[20%] right-[5%]" />
            </div>
            <div className="pc:hidden relative w-[78%] bg-cover bg-center bg-no-repeat aspect-[1028/987]" style={{backgroundImage: `url(${podiumMobile})`}}>
                <Swiper 
                    modules={[Navigation, Pagination, Autoplay]}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        enabled: true,
                        prevEl: '.sec5banner-prev',
                        nextEl: '.sec5banner-next',
                    }}
                    autoplay={{ delay: 3000 }}
                    className="sec5-swiper-wrapper w-full mt-[15%]"
                >
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(dragon)} alt="" className="w-[50%]" />
                    </SwiperSlide>
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(fox)} alt="" className="w-[90%]" />
                    </SwiperSlide>
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(male)} alt="" className="w-[45%] -mt-[8%]" />
                    </SwiperSlide>
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(female)} alt="" className="w-[50%]" />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="pc:hidden">
                <div className="sec5banner-prev"></div>
                <div className="sec5banner-next"></div>
            </div>
            <div className="pc:hidden absolute z-10 top-[21%] left-[12%] w-[15%] bg-cover bg-center bg-no-repeat aspect-[103/301]" style={{backgroundImage: `url(${weaponName})`}}>
                <p className="w-3/4 bg-gradient-to-b from-[#FFFFFF] to-[#D27B19] text-transparent bg-clip-text pt-[30%] pl-[20%] text-center whitespace-break-spaces">
                    Thần khí - Thần khí
                </p>
            </div>
            {/* <div ref={targetRef} className="opacity-0 relative pc:w-[60%] w-full flex justify-center items-center">
                <Swiper 
                    modules={[Navigation, Pagination, Autoplay]}
                    slidesPerView={1}
                    loop={true}
                    navigation={{
                        enabled: true,
                        prevEl: '.sec5banner-prev',
                        nextEl: '.sec5banner-next',
                    }}
                    autoplay={{ delay: 3000 }}
                    className="sec5-swiper-wrapper w-full"
                >
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(weapon1)} alt="" className="mb:w-[90%] tb:w-[90%]" />
                    </SwiperSlide>
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(weapon2)} alt="" className="mb:w-[90%] tb:w-[90%]" />
                    </SwiperSlide>
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(weapon3)} alt="" className="mb:w-[90%] tb:w-[90%]" />
                    </SwiperSlide>
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(weapon4)} alt="" className="mb:w-[90%] tb:w-[90%]" />
                    </SwiperSlide>
                    <SwiperSlide className="sec5-swiper-slide" >
                        <img src={String(weapon5)} alt="" className="mb:w-[90%] tb:w-[90%]" />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div>
                <div className="sec5banner-prev"></div>
                <div className="sec5banner-next"></div>
            </div> */}
            <div className="w-4/5 pc:w-[40%] mt-[5%] pc:mt-[3%] grid grid-cols-3 gap-[10%]">
                <Link to={zaloOAUrl} className="bg-cover bg-center bg-no-repeat aspect-[275/402]" style={{backgroundImage: `url(${contact})`}}></Link>
                <Link to={zaloOAUrl} className="bg-cover bg-center bg-no-repeat aspect-[275/402]" style={{backgroundImage: `url(${zalo})`}}></Link>
                <Link to={fbUrl} className="bg-cover bg-center bg-no-repeat aspect-[275/402]" style={{backgroundImage: `url(${fb})`}}></Link>
            </div>
        </div>
    )
}

export default Section5