import { FC } from "react"
import { capitalizeFirstLetter } from "../utils/common";

const TabContent:FC<{activeIdx: number, onClick: (index: number)=> void, listTab: any[], styleCSS?: String}> = ({ activeIdx, onClick, listTab, styleCSS }) => {
    const btnBg = require('../assets/img/btn-bg.png');
    return (
        <div className={`w-full flex justify-between items-center pc:text-xl text-sm text-[#415F94] ${styleCSS}`}>
            {
                listTab.map((val, idx) => (
                    idx === activeIdx ?
                    <div key={idx} className={`w-[30%] bg-cover bg-center bg-no-repeat aspect-[329/104] flex justify-center items-center cursor-pointer ${activeIdx != idx ? "bg-none text-[#415F94]" : "text-white"}`} 
                        style={{backgroundImage: `url(${btnBg})`}} 
                        onClick={() => onClick(idx)}
                    >
                        {capitalizeFirstLetter(val.name)}
                    </div> :
                    <div key={idx} className="mx-auto cursor-pointer hover:brightness-150" onClick={() => onClick(idx)}>
                        {capitalizeFirstLetter(val.name)}
                    </div>
                    
                ))
            }
        </div>
    )
}

export default TabContent