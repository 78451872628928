import NewsPage from "../pages/News/NewsPage";
import HomePage from "../pages/HomePage/HomePage";
import PaymentInstructionPage from "../pages/PaymentInstruction/PaymentInstructionPage";
import TermPage from "../pages/Terms/TermPage";
import NewsDetailPage from "../pages/DetailPage/DetailPage";

export const Routers = [
    // {
    //     path: "*",
    //     component: () => <NotFoundPage />
    // },
    {
        path: "/",
        component: () => <HomePage />
    },
    {
        path: "/su-kien",
        component: () => <NewsPage type="EVENT" />
    },
    {
        path: "/tin-tuc",
        component: () => <NewsPage type="NEWS" />
    },
    {
        path: "/huong-dan",
        component: () => <NewsPage type="TUTORIAL" />
    },
    {
        path: "/dieu-khoan",
        component: () => <TermPage />
    },
    {
        path: "/huong-dan-nap-the",
        component: () => <PaymentInstructionPage />
    },
    {
        path: "/:type/:path/:postId",
        component: () => <NewsDetailPage />
    }
]