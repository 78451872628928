import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GameUrl, PaymentUrl, fbUrl, zaloOAUrl } from "../config/gameconf";
import LeftMenu from "./LeftMenu";

const Header = () => {
    const bgPc = require('../assets/img/header-bg-pc.jpg');
    const bgMobile = require('../assets/img/header-bg-mobile.jpg');
    const logo = require('../assets/img/logo.png');
    const btnPlay = require('../assets/img/btn-playgame.png')
    const btnPurchase = require('../assets/img/btn-purchase.png')
    const menuHome = require('../assets/img/home-icon.png')
    const menuIcon = require('../assets/img/menu-icon.png')
    const closeIcon = require('../assets/img/close-icon.png')

    const [openLeftMenu, setOpenLeftMenu] = useState(false);

    let navigate = useNavigate(); 
    const routeChange = (path: string) =>{
        navigate(path);
    }
    
    type CustomStyleProperties = {
        [key: string]: string | number;
    };

    const titles = [
        {"title": "Trang chủ", "path": ""},
        {"title": "Sự kiện", "path": "/su-kien"},
        {"title": "Tin tức", "path": "/tin-tuc"},
        {"title": "Hướng dẫn", "path": "/huong-dan"},
        {"title": "Hỗ trợ", "path": zaloOAUrl},
        {"title": "Cộng đồng", "path": fbUrl},
    ]

    return (
        <div className={`fixed z-[100] w-full bg-cover bg-center bg-no-repeat aspect-[1284/179] pc:aspect-[2004/92] bg-[image:var(--bg-mobile-url)] pc:bg-[image:var(--bg-pc-url)]`} style={{'--bg-mobile-url': `url(${String(bgMobile)})`, '--bg-pc-url': `url(${String(bgPc)})`} as CustomStyleProperties} >
            <LeftMenu isShow={openLeftMenu} hideFunc={(path) => {routeChange(path); setOpenLeftMenu(false);}} />
            <Link to={"/"} className="no-brightness w-[15%] pc:w-[7%] absolute pc:top-[15%] top-[24%] left-[7%] bg-cover bg-center bg-no-repeat aspect-[1/1] mr-4" style={{backgroundImage: `url(${String(logo)})`}}></Link>
            <div className="w-full h-full flex items-center">
                {/* PC */}
                <div className="mb:hidden tb:hidden ml-[35%] min-w-[55%] flex grid-cols-6 justify-between">
                    {titles.map((el, idx) => (
                        <div key={idx} className="relative flex whitespace-nowrap text-base">
                            {idx === 0 && <Link to={"/"} className="absolute top-0 -left-[35%] h-full bg-cover bg-center bg-no-repeat aspect-[1/1]" style={{backgroundImage: `url(${String(menuHome)})`}}></Link>}
                            <Link to={el.path} className="bg-gradient-to-b from-[#D19053] to-[#FAE4B6] text-transparent bg-clip-text uppercase" style={{textShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'}}>{el.title}</Link>
                            {/* {idx === 5 && <FontAwesomeIcon icon={faCaretDown} className="absolute top-[70%] right-[50%] text-[#D19053]" />} */}
                        </div>
                    ))}
                </div>

                {/* Mobile */}
                <div className="pc:hidden absolute top-0 right-0 flex justify-end items-center w-4/5 h-full">
                    <Link to={GameUrl} target="_blank" className="h-[47%] bg-cover bg-center bg-no-repeat aspect-[169/44] mr-1" style={{backgroundImage: `url(${String(btnPlay)})`}}></Link>
                    <Link to={PaymentUrl} target="_blank" className="h-[47%] bg-cover bg-center bg-no-repeat aspect-[169/44] mr-1" style={{backgroundImage: `url(${String(btnPurchase)})`}}></Link>
                    <Link to={"/"} className="h-[50%] bg-cover bg-center bg-no-repeat aspect-[1/1] mr-4" style={{backgroundImage: `url(${String(menuHome)})`}}></Link>
                    {openLeftMenu === false ?
                    <img src={menuIcon} alt="" className="mr-4 w-[6%] hover:brightness-125 cursor-pointer" onClick={() => setOpenLeftMenu(true)} />
                    :
                    <img src={closeIcon} alt="" className="mr-4 w-[6%] hover:brightness-125 cursor-pointer" onClick={() => setOpenLeftMenu(false)} />}
                </div>
            </div>
        </div>
    )
}

export default Header