import { useContext } from "react"
import GlobalContext from "../store/contexts/GlobalContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Video = () => {
    const btnClose = require("../assets/img/btn-close.png");

    const { updateShowVideo } = useContext(GlobalContext)

    return (
        <div className="fixed top-0 left-0 z-[1000] w-screen h-screen" style={{backgroundColor: 'rgba(0, 0, 0, 0.85)'}} onClick={() => updateShowVideo?.(false)}>
            <div className="w-full h-screen flex justify-center items-center">
                <video
                    controls
                    autoPlay
                    className="max-w-full align-middle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    >
                    <source src="https://h5.zadn.vn/videos/tvlcm/TVLCM_Crossgame_1_compress.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <img
                    className="absolute top-0 right-0 w-[30px] h-[27px] cursor-pointer"
                    src={String(btnClose)}
                    alt="Button Close"
                    onClick={() => updateShowVideo?.(false)}
                />
            </div>
        </div>
    )
}

export default Video