import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalContext from "./store/contexts/GlobalContext";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Routers } from "./config/routers";
import { useState } from "react";
import Loading from "./components/Loading";

function App() {
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState("");
    const [showVideo, setShowVideo] = useState(false);

    const updateIsLoading = (isLoading: boolean) => {
        setIsLoading(isLoading);
    }

    const updateAlert = (alert: string) => {
        setAlert(alert);
    }

    const updateShowVideo = (showVideo: boolean) => {
        setShowVideo(showVideo);
    }

    return (
        <GlobalContext.Provider
            value={{
                isLoading,
                updateIsLoading,
                alert,
                updateAlert,
                showVideo,
                updateShowVideo,
            }}>
            <BrowserRouter>
            <div className="w-full bg-[#DFE9F1] overflow-hidden">
                {isLoading && <Loading />}
                <Header />
                <Routes>
                {
                    Routers.map((el, idx) => (
                    el.path && <Route key={idx} path={el.path} element={<el.component />}></Route>
                    ))
                }
                </Routes>
                <Footer />
            </div>
            </BrowserRouter>
        </GlobalContext.Provider>
    )
}

export default App;