import { FC, useContext, useEffect, useRef, useState } from "react";
import GlobalContext from "../../store/contexts/GlobalContext";
import { getListNews } from "../../helper/api-container";
import { NewsItemIface } from "../../interface/EntityIface";
import { convertTimestampToSimpleDate } from "../../utils/timeutils";
import { Link, useNavigate } from "react-router-dom";
import Floating from "../../components/Floating";
import ReactPaginate from "react-paginate";
import { AppId } from "../../config/gameconf";

const NewsPage:FC<{type: string}> = ({type}) => {
    const bannerPc = require('../../assets/img/banner-subpage-pc.jpg');
    const bannerMobile = require('../../assets/img/banner-subpage-mobile.jpg');
    const bgPc = require('../../assets/img/subpage-bg-pc.jpg');
    const bgMobile = require('../../assets/img/subpage-bg-mobile.jpg');
    const title = require('../../assets/img/section2-title.png')
    const showmore = require('../../assets/img/showmore-icon.png')
    const messagesEndRef = useRef<null | HTMLDivElement>(null); 
    let navigate = useNavigate(); 
    const routeChange = (path: string) =>{
        navigate(path);
    }
    
    type CustomStyleProperties = {
        [key: string]: string | number;
    };

    const {updateAlert } = useContext(GlobalContext);
    const [newsList, setNewsList] = useState<NewsItemIface[]>([]);
    const itemsPerPage = 5;
    const [pageCount, setpageCount] = useState(0);

    const fetchNewsData = async (from: number, count: number, type: string) => {
        try {
            const resp = await getListNews(AppId, from, count , type, true);
            
            if (resp.error_code >= 0 && resp.data !== null && resp.data !== undefined) {
                setNewsList(resp.data);

                let extraData = JSON.parse(JSON.stringify(resp.extraData));
                let page = Math.ceil(extraData.count / itemsPerPage);
                setpageCount(page);
            }            
        } catch (ex) {
            updateAlert?.('Đã có lỗi xảy ra');
        }
    }

    useEffect(() => {
        fetchNewsData(0, itemsPerPage, type);
    }, [type])

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView(true)
    })

    const handlePageClick = (event: any) => {
        fetchNewsData(event.selected * itemsPerPage, itemsPerPage, type);
    };

    const getPath = () => {
        switch (type) {
            case "NEWS":
                return "/tin-tuc";
            case "EVENT":
                return "/su-kien";
            case "TUTORIAL":
                return "/huong-dan";
            default:
                return "/tin-tuc";
        }
    }

    const styleCss = {
        '@media only screen and (min-width: 1024px)': {
            display: 'none',
        },
    }

    

    return (
        <div className="mt-[13.5%] pc:mt-[6%]">
            <Floating />
            <img src={String(bannerPc)} alt="" className="-mt-[3%] w-full mb:hidden tb:hidden" />
            <img src={String(bannerMobile)} alt="" className="w-full pc:hidden" />
            <div className={`overflow-hidden relative flex flex-col items-center w-full px-[2.5%] pc:px-[12.5%] bg-cover bg-center bg-no-repeat 
                aspect-[1284/2269] pc:aspect-[167/221] bg-[image:var(--bg-mobile-url)] pc:bg-[image:var(--bg-pc-url)]`} style={{'height': `calc(${newsList.length} * var(--news-item-height) + 20vw)`, '--bg-mobile-url': `url(${String(bgMobile)})`, '--bg-pc-url': `url(${String(bgPc)})`} as CustomStyleProperties} >
                <div ref={messagesEndRef} className="mb-[6%]"/>
                <img src={String(title)} alt="Title" className="pc:w-1/4 w-2/5 pt-[2%]" />
                <div className="w-full flex flex-col items-center">
                    {newsList && newsList.map((val, idx) => (
                        <div key={idx} className="w-full mt-[3%]">
                            <div className="w-full h-[1px] bg-[#415F94]"></div>
                            <div className="mt-[2%] w-[96%] grid grid-cols-[40%_60%]">
                                <img src={String(val.thumbnail)} alt="" className="hover:cursor-pointer" onClick={() => routeChange(getPath()+"/"+String(val.path+"/"+val.postId))} />
                                <div className="relative mt-[2%] ml-[5%]">
                                    <p className="text-xs pc:text-sm text-[#6C6C6C] italic">{convertTimestampToSimpleDate(val.time)}</p>
                                    <Link to={getPath()+"/"+String(val.path+"/"+val.postId)} className="mt-[2%] text-sm pc:text-xl text-[#263567] hover:text-blue-500 font-medium show-2-lines">{val.title}</Link>
                                    <div className="absolute bottom-[4%] flex items-center">
                                        <Link to={getPath()+"/"+String(val.path+"/"+val.postId)} className="text-xs pc:text-base text-[#4B64A0] underline">Xem chi tiết</Link>
                                        <img src={String(showmore)} alt="" className="ml-2 h-[16px]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <ReactPaginate
                    className="absolute bottom-[2%] pc:bottom-[3%] flex justify-center items-center gap-[10%] text-base pc:text-xl text-[#263567]"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    activeClassName="underline"
                    renderOnZeroPageCount={null}
                />
            </div>
        </div>
    )
}

export default NewsPage