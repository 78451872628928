export const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const decodeHtmlEntities = (content: string) => {
    const text = document.createElement("textarea");
    text.innerHTML = content;
    return text.value;
}

export const openInNewTab = (url: string) => {
    console.log(url);
    
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}