import Section1 from "../../components/Section1"
import Section2 from "../../components/Section2"
import Section4 from "../../components/Section4"
import Section5 from "../../components/Section5"
import Floating from "../../components/Floating"

const HomePage = () => {   

    window.scrollTo({
        left: 0,
        top: 0
    });
    return (
        <div className="mt-[13.5%] pc:mt-[4.6%]">
            <Floating />
            <Section1 />
            <Section2 />
            <Section4 />
            <Section5 />
        </div>
    )
}

export default HomePage