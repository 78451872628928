import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

const Footer = () => {
    const logoZagoo = require('../assets/img/logo-zagoo.png');
    const logoYoudong = require('../assets/img/logo-youdong.png');

    return (
        <div className="relative pt-[3%] pb-[5%] bg-gradient-to-b from-[#064982] to-[#032147] flex flex-col mb:text-[8px] mb:leading-[11px] text-white items-center" >
            <div className="flex justify-center gap-[2%] mb:gap-[2%]">
                <img src={String(logoZagoo)} alt="Logo Zagoo" className="w-[15%] mb:w-[18%] aspect-[189/73]" />
                <img src={String(logoYoudong)} alt="Logo Youdong" className="w-[15%] mb:w-[18%] aspect-[339/131]" />
            </div>
            <p className="mt-[2%]">
                Công Ty TNHH ZIE
            </p>
            <p>
                Địa chỉ trụ sở chính: Tầng 17, Tòa Nhà TNR Tower, số 54A Nguyễn Chí Thanh, Phường Láng Thượng,
            </p>
            <p>
                Quận Đống Đa, Thành phố Hà Nội, Việt Nam
            </p>
            <div className="mt-[2%] w-full flex gap-[1%] justify-center text-[#51a8df] underline">
                <Link to={"/dieu-khoan"}>
                    Điều khoản
                </Link>
                <p>|</p>
                <Link to={"https://zagoo.vn/complaint"} target="_blank">
                    Quy trình khiếu nại
                </Link>
            </div>
            
        </div>
    )
}

export default Footer