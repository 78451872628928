import { useContext, useEffect, useRef, useState } from "react";
import GlobalContext from "../../store/contexts/GlobalContext";
import { AppId } from "../../config/gameconf";
import { getNewsDetail } from "../../helper/api-container";
import { useParams } from "react-router";
import { NewsItemIface } from "../../interface/EntityIface";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { convertTimestampToSimpleDate } from "../../utils/timeutils";
import { Link } from "react-router-dom";
import {decodeHtmlEntities} from "../../utils/common";


const DetailPage = () => {
    const bannerPc = require('../../assets/img/banner-subpage-pc.jpg');
    const bannerMobile = require('../../assets/img/banner-subpage-mobile.jpg');
    const bgPc = require('../../assets/img/subpage-bg-pc.jpg');
    const bgMobile = require('../../assets/img/subpage-bg-mobile.jpg');
    const title = require('../../assets/img/section2-title.png')
    const homeIcon = require('../../assets/img/home-icon-v2.png')
    const messagesEndRef = useRef<null | HTMLDivElement>(null); 

    const { updateIsLoading, updateAlert } = useContext(GlobalContext);
    const [news, setNews] = useState<NewsItemIface>();
    const {type, postId} = useParams();

    type CustomStyleProperties = {
        [key: string]: string | number;
    };

    const getPathName = () => {
        switch (type) {
            case 'tin-tuc':
                return 'Tin tức';
            case 'su-kien':
                return 'Sự kiện';
            case 'huong-dan':
                return 'Hướng dẫn';
            default:
                return '';
        }
    }
    
    const fetchNewsDetail = async () => {
        updateIsLoading?.(true);
        try {
            const resp = await getNewsDetail(AppId, Number(postId), true);
            
            if (resp.error_code >= 0 && resp.data) {
                setNews(resp.data);

                window.scrollTo({
                    left: 0,
                    top: 0,
                });
            }            
        } catch (ex) {
            updateAlert?.('Đã có lỗi xảy ra');
        } finally {
            updateIsLoading?.(false);
        }
    }

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView(true);
    })

    useEffect(() => {
        fetchNewsDetail();
    }, [postId])
    
    return (
        <div className="mt-[13.5%] pc:mt-[6%]">
            <img src={String(bannerPc)} alt="" className="-mt-[3%] w-full mb:hidden tb:hidden" />
            <img src={String(bannerMobile)} alt="" className="w-full pc:hidden" />
            {news && <div className={classNames(`overflow-hidden relative flex flex-col items-center w-full px-[2.5%] pc:px-[12.5%] bg-cover bg-center bg-no-repeat 
             bg-[image:var(--bg-mobile-url)] pc:bg-[image:var(--bg-pc-url)]`)} style={{'--bg-mobile-url': `url(${String(bgMobile)})`, '--bg-pc-url': `url(${String(bgPc)})`} as CustomStyleProperties} >
                <div ref={messagesEndRef} className="mb-[6%]"/>
                <img src={String(title)} alt="Title" className="pc:w-1/4 w-2/5 pt-[2%]" />
                <div className="w-full px-[5%] text-[#415F94] text-sm pc:text-base whitespace-nowrap">
                    <div className="flex items-center gap-[1%]">
                        <img src={String(homeIcon)} alt="" className="w-[8%] pc:w-[5%]" />
                        <FontAwesomeIcon icon={faAngleRight} className="w-[1.5%] pc:w-[0.7%]" />
                        <Link to={String("/"+type)}>{getPathName()}</Link>
                        <FontAwesomeIcon icon={faAngleRight} className="w-[1.5%] pc:w-[0.7%]" />
                        <Link to={""} className="text-ellipsis overflow-hidden">{news?.title}</Link>
                    </div>
                    
                </div>
                <div className="w-full mt-[3%] h-[1px] bg-[#415F94]"></div>
                <div className="w-full mt-[3%] px-[5%] overflow-y-scroll">
                    <p className="text-base pc:text-2xl text-[#263667]">{news?.title}</p>
                    <p className="mt-[3%] text-sm italic">{convertTimestampToSimpleDate(news?.time)}</p>
                    <div id="detail" className="mt-[3%] text-align"
                        dangerouslySetInnerHTML={{ __html: decodeHtmlEntities(news.content) }}
                    ></div>
                </div>
             </div>}
        </div>
    )
}

export default DetailPage