import { Autoplay, EffectCoverflow, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import "swiper/css/effect-coverflow";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import "../assets/css/section4.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import anime from 'animejs';
import { useEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Section4 = () => {
    const bgPc = require('../assets/img/sec4-bg-pc.jpg');
    const bgMobile = require('../assets/img/sec4-bg-mobile.jpg');
    const title = require('../assets/img/section4-title.png');
    const banner = require('../assets/img/section4-banner.png');
    const img1 = require('../assets/img/banner-1.jpg');
    const img2 = require('../assets/img/banner-2.jpg');
    const img3 = require('../assets/img/banner-3.jpg');
    const img4 = require('../assets/img/banner-4.jpg');
    const img5 = require('../assets/img/banner-5.jpg');

    const targetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const targetElement = targetRef.current;
        
        const handleScroll = () => {
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                if (rect.top >= 0 && rect.top <= window.innerHeight) {
                    anime({
                        targets: targetElement,
                        opacity: [0, 1],
                        translateY: ['200px', '0px'],
                        duration: 1000,
                        easing: 'easeInOutQuad',
                        });
        
                    // Remove the scroll event listener after the animation has triggered
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup by removing the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    type CustomStyleProperties = {
        [key: string]: string | number;
    };

    return (
        <div className={`relative w-full flex flex-col items-center bg-cover bg-center bg-no-repeat pc:aspect-[1002/419] aspect-[428/335] pc:bg-[image:var(--bg-pc-url)] bg-[image:var(--bg-mobile-url)]`} style={{'--bg-mobile-url': `url(${String(bgMobile)})`, '--bg-pc-url': `url(${String(bgPc)})`} as CustomStyleProperties} >
            <img src={String(title)} alt="" className="mt-[2%] pc:-mt-[2%] pc:w-[20%] w-2/5" />
            <div ref={targetRef} className="opacity-0 pc:w-[75%] w-full flex justify-center items-center dropShadow">
                <Swiper 
                    modules={[EffectCoverflow, Navigation, Pagination, Autoplay]}
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    loop={true}
                    navigation={{
                        enabled: true,
                        prevEl: '.sec4banner-prev',
                        nextEl: '.sec4banner-next',
                    }}
                    pagination={{
                        el: '.sec4-pagination',
                        clickable: true
                    }}
                    autoplay={{ delay: 3000 }}
                    className="sec4-swiper-wrapper w-full"
                >
                        <SwiperSlide className='sec4-swiper-slide'>
                            <img src={String(img1)} alt="Temp"/>
                            <img src={String(banner)} alt="" className='absolute -bottom-[4.2%] w-[87%]' />
                        </SwiperSlide>
                        <SwiperSlide className='sec4-swiper-slide'>
                            <img src={String(img2)} alt="Temp"/>
                            <img src={String(banner)} alt="" className='absolute -bottom-[4.2%] w-[87%]' />
                        </SwiperSlide>
                        <SwiperSlide className='sec4-swiper-slide'>
                            <img src={String(img3)} alt="Temp"/>
                            <img src={String(banner)} alt="" className='absolute -bottom-[4.2%] w-[87%]' />
                        </SwiperSlide>
                        <SwiperSlide className='sec4-swiper-slide'>
                            <img src={String(img4)} alt="Temp"/>
                            <img src={String(banner)} alt="" className='absolute -bottom-[4.2%] w-[87%]' />
                        </SwiperSlide>
                        <SwiperSlide className='sec4-swiper-slide'>
                            <img src={String(img5)} alt="Temp"/>
                            <img src={String(banner)} alt="" className='absolute -bottom-[4.2%] w-[87%]' />
                        </SwiperSlide>
                </Swiper>
            </div>
            <div className="sec4-pagination pc:mt-2 mt-[3%] w-full flex justify-center gap-1">
            </div>
            <div>
                <div className="sec4banner-prev"></div>
                <div className="sec4banner-next"></div>
            </div>
        </div>
    )
}

export default Section4