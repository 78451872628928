import classNames from "classnames";
import { FC } from "react"
import { Link } from "react-router-dom"

const titles = [
    { name: "Trang Chủ", path: "/" },
    { name: "Tin Tức", path: "/tin-tuc" },
    { name: "Sự Kiện", path: "/su-kien" },
    { name: "Hướng Dẫn", path: "/huong-dan" },
    { name: "Hỗ Trợ", path: "/" },
    { name: "Cộng Đồng", path: "/" },
    { name: "Zalo", path: "/" },
    { name: "Facebook", path: "/" },
];

const LeftMenu:FC<{
    isShow: boolean,
    hideFunc: (path: string) => void,
}> = ({isShow, hideFunc}) => {

    const dropboxBg = require('../assets/img/dropbox-bg.jpg')

    return (
        <div className={classNames("fixed w-screen h-screen top-[14vw] left-0", isShow ? "visible opacity-100" : "invisible opacity-0")} style={{'height': `calc(100vh - 14vw)`}}>
            
            <div className="absolute right-0 bg-cover bg-center bg-no-repeat mb:w-full tb:w-3/4 h-full flex flex-col items-center" style={{'height': `calc(100vh - 14vw)`, backgroundImage: `url(${String(dropboxBg)})`}}>
                {
                    titles.map((val, idx) => (
                        <div key={idx} className="w-full flex flex-col items-center hover:bg-gradient-to-b from-[#567AAC] to-[#859ECB] cursor-pointer" onClick={() => hideFunc(val.path)}>
                            <Link to={val.path} className="mt-[3%] bg-gradient-to-b from-[#CE883C] to-[#FCF0C8] text-transparent bg-clip-text font-medium" style={{textShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)'}}>{val.name}</Link>
                            <div className="w-full h-[1px] mt-[3%] bg-[#BCA671]"></div>
                        </div>
                    ))
                }
            </div>
            {/* <div className="bg-black opacity-75 w-full h-full"></div> */}

            {/* <div className="absolute top-0 left-0 flex w-full h-full">
                <div className="flex-[1]" onClick={hideFunc}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-white cursor-pointer ml-[25%] font-thin mt-16 w-[32px] h-[32px]"
                    />
                </div>
                <div className={classNames(
                    "bg-white relative pt-3 px-6  overflow-hidden duration-100 ease-in-out",
                    isShow ? "w-4/5 flex flex-col gap-4" : "w-0"
                )}>
                    {
                        listTab.map((val, idx) => (
                            <div key={idx} className="w-3/4">
                                <Link to={val.path} onClick={hideFunc} >{val.name}</Link>
                            </div>
                        ))
                    }
                </div>
            </div> */}
        </div>
    )
}

export default LeftMenu