import { useNavigate } from "react-router";
import { GameUrl, PaymentUrl, fbUrl, zaloOAUrl } from "../config/gameconf";
import { useEffect, useRef } from "react";
import {openInNewTab} from "../utils/common";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import anime from 'animejs';

const Floating = () => {
    const floating = require('../assets/img/floating.png');
    const paymentIcon = require('../assets/img/payment-icon.png');
    const playgameIcon = require('../assets/img/playgame-icon.png');
    const zaloIcon = require('../assets/img/zalo-icon.png');
    const facebookIcon = require('../assets/img/facebook-icon.png');
    const homefloatingIcon = require('../assets/img/homefloating-icon.png');
    const contactIcon = require('../assets/img/contact-icon.png');

    let navigate = useNavigate(); 
    const routeChange = (path: string) =>{
        navigate(path);
    }

    const floatRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const floatElement = floatRef.current;

        if (floatElement) {
            anime({
                targets: floatElement,
                translateY: ['-500px', '0px'],
                duration: 1000,
                easing: 'easeInOutQuad',
            });
        }
    }, []);

    return (
        <div ref={floatRef} className="mb:hidden tb:hidden fixed z-50 top-[19vh] right-1 h-[65vh] bg-cover bg-center bg-no-repeat aspect-[215/637] flex flex-col items-center" style={{backgroundImage: `url(${floating})`}}>
            <img src={String(paymentIcon)} alt="" className="w-[60%] mt-[140%] cursor-pointer hover:brightness-150" onClick={() => openInNewTab(PaymentUrl)} />
            <img src={String(playgameIcon)} alt="" className="w-[60%] mt-[2%] cursor-pointer hover:brightness-150" onClick={() => openInNewTab(GameUrl)} />
            <img src={String(zaloIcon)} alt="" className="w-[60%] mt-[2%] cursor-pointer hover:brightness-150" onClick={() => openInNewTab(zaloOAUrl)} />
            <img src={String(facebookIcon)} alt="" className="w-[60%] mt-[2%] cursor-pointer hover:brightness-150" onClick={() => openInNewTab(fbUrl)} />            <div className="w-[60%] mt-[2%] flex">
            <img src={String(homefloatingIcon)} alt="" className="w-1/2 cursor-pointer hover:brightness-150" onClick={() => routeChange("/")} />
            <img src={String(contactIcon)} alt="" className="w-1/2 cursor-pointer hover:brightness-150" onClick={() => openInNewTab(zaloOAUrl)} />
            </div>
        </div>
    )
}

export default Floating