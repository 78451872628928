import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import "../assets/css/section2.css";
import { convertTimeStampToDate } from '../utils/timeutils';
import TabContent from './TabContent';
import GlobalContext from '../store/contexts/GlobalContext';
import { getListNews } from '../helper/api-container';
import { NewsItemIface } from '../interface/EntityIface';
import { AppId } from '../config/gameconf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import anime from 'animejs';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface tabItem {
    idx: number,
    name: string,
    path: string,
    type: string,
}

const listTab: tabItem[] = [
    { idx: 0, name: "Tin Tức", path: "/tin-tuc", type: "NEWS" },
    { idx: 1, name: "Sự Kiện", path: "/su-kien", type: "EVENT" },
    { idx: 2, name: "Hướng Dẫn", path: "/huong-dan", type: "TUTORIAL" },
];

const Section2 = () => {
    const bgPc = require('../assets/img/sec2-bg-pc.jpg');
    const bgMobile = require('../assets/img/sec2-bg-mobile.jpg');
    const sction2Title = require('../assets/img/section2-title.png');
    const sec2Banner = require('../assets/img/sec2-banner.png');
    const board = require('../assets/img/board.png');
    const btnBg = require('../assets/img/btn-bg.png');

    const fragmentPayment = require('../assets/img/fragment-payment.png');
    const fragmentTerms = require('../assets/img/fragment-terms.png');
    const fragmentHandbook = require('../assets/img/fragment-handbook.png');
    const fragmentSupport = require('../assets/img/fragment-support.png');

    const { updateIsLoading, updateAlert } = useContext(GlobalContext);

    const [currentTab, setCurrentTab] = useState<tabItem>(listTab[0]);
    const [newsList, setNewsList] = useState<NewsItemIface[]>([]);

    const itemClicked = (idx: number) => {
        setCurrentTab(listTab[idx]);
    }

    let navigate = useNavigate(); 
    const routeChange = (path: string) =>{
        navigate(path);
    }

    const fetchNewsData = async (from: number, count: number, type: string) => {
        try {
            const resp = await getListNews(AppId, from, count , type, true);
            if (resp.error_code >= 0 && resp.data !== null && resp.data !== undefined) {
                setNewsList(resp.data);
            }            
        } catch (ex) {
            updateAlert?.('Đã có lỗi xảy ra');
        }
    }
    
    useEffect(() => {
        fetchNewsData(0, 6, currentTab.type);
    }, [currentTab])

    const targetRefBoard = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const targetElement = targetRefBoard.current;
        
        const handleScroll = () => {
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                if (rect.top >= 0 && (rect.top + 50) <= window.innerHeight) {
                    anime({
                        targets: targetElement,
                        opacity: [0, 1],
                        translateX: ['-200px', '0px'],
                        duration: 1000,
                        easing: 'easeInOutQuad',
                        });
        
                    // Remove the scroll event listener after the animation has triggered
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup by removing the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const targetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const targetElement = targetRef.current;
        
        const handleScroll = () => {      
            if (targetElement) {
                const rect = targetElement.getBoundingClientRect();
                if (rect.top >= 0 && (rect.top + 50) <= window.innerHeight) {
                    anime({
                        targets: targetElement,
                        opacity: [0, 1],
                        translateY: ['-200px', '0px'],
                        duration: 1000,
                        easing: 'easeInOutQuad',
                        });
        
                    // Remove the scroll event listener after the animation has triggered
                    window.removeEventListener('scroll', handleScroll);
                }
            }
        };

        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup by removing the scroll event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    type CustomStyleProperties = {
        [key: string]: string | number;
    };

    return (
        <div className={`relative w-full flex flex-col items-center bg-cover bg-center bg-no-repeat pc:aspect-[2004/1471] aspect-[428/1169] pc:bg-[image:var(--bg-pc-url)] bg-[image:var(--bg-mobile-url)]`} style={{'--bg-mobile-url': `url(${String(bgMobile)})`, '--bg-pc-url': `url(${String(bgPc)})`} as CustomStyleProperties} >
            <img src={String(sction2Title)} alt="Title" className="pc:w-[20%] w-2/5 pc:pt-[1%] pt-[7%]" />
            
            <div ref={targetRefBoard} className="opacity-0 w-full pc:-mt-[1.5%] pc:px-[12.5%] pc:grid pc:grid-cols-[57.8%_42.2%] mb:mt-[5%] tb:mt-[5%] tb:flex tb:flex-col tb:items-center mb:flex mb:flex-col mb:items-center">
                {newsList != null && <div className="flex flex-col items-center w-full">
                    <Swiper
                        modules={[ Navigation, Pagination, Autoplay]}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={1}
                        loop={true}
                        pagination={{
                            el: '.sec2-pagination',
                            clickable: true
                        }}
                        autoplay={{ delay: 3000 }}
                        className="pc:w-full pc:h-full tb:w-[95%] mb:w-[95%]"
                    >                            
                        {
                            newsList.map((val, idx) => (
                                <SwiperSlide key={idx} className='relative sec2-swiper-slide' onClick={() => routeChange("/tin-tuc/"+String(val.path))}>
                                    <img src={String(val.thumbnail)} alt="" className='w-full tb:w-[97%] mb:w-[97%]' />
                                    <img src={String(sec2Banner)} alt="" className='absolute w-[98%] h-[98%]' />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    <div className="sec2-pagination pc:mt-1 w-full flex justify-center tb:mt-[1%] mb:mt-[1%]">
                    </div>
                </div>}
                <div className="tb:w-[95%] mb:w-[95%] tb:mt-[3%] mb:mt-[3%] w-full px-[10%] grid grid-rows-[1fr_3fr_1fr] bg-cover bg-center bg-no-repeat aspect-[1284/973]" style={{backgroundImage: `url(${board})`}}>
                    <TabContent
                        activeIdx={currentTab.idx}
                        onClick={(idx) => itemClicked(idx)}
                        listTab={listTab}
                        styleCSS="mt-[5%]"
                    />
                    <div className="z-20 flex flex-col gap-2 overflow-y-scroll overflow-visible">
                        {
                            newsList != null && newsList.length > 0 && newsList.slice(0, 6).map((val, idx) => (
                                <div key={idx} className="w-full flex flex-col gap-2">
                                    <div className="w-full h-[1px] bg-[#415F94]"></div>
                                    <div key={idx} className="flex justify-between text-xs">
                                        <Link to={currentTab.path+"/"+String(val.path+"/"+val.postId)} className="w-4/5 whitespace-nowrap overflow-hidden text-ellipsis text-[#263667] pc:text-base hover:text-blue-500">{val.title}</Link>
                                        <p className='text-[#263667] pc:text-base'>{convertTimeStampToDate(val.time)}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="z-20 flex justify-center items-center">
                        <Link className="w-[30%] bg-cover bg-center bg-no-repeat aspect-[329/104] text-white pc:text-base text-xs flex justify-center items-center"
                        style={{ backgroundImage: `url(${btnBg})` }} to={currentTab.path}>Xem Thêm</Link>
                    </div>
                </div>
            </div>

            <div ref={targetRef} className="opacity-0 pc:w-[45%] w-[95%] pc:mt-[6%] mt-[18%] grid grid-cols-2 grid-rows-2 pc:gap-[5%] gap-[1%]">

                <Link to={"/huong-dan/huong-dan-nap-the/481"} className="bg-cover bg-center bg-no-repeat aspect-[608/265]" style={{backgroundImage: `url(${fragmentPayment})`}} />
                <Link to={"/dieu-khoan"} className="bg-cover bg-center bg-no-repeat aspect-[608/265]" style={{backgroundImage: `url(${fragmentTerms})`}} />
                <Link to={"/huong-dan"} className="bg-cover bg-center bg-no-repeat aspect-[608/265]" style={{backgroundImage: `url(${fragmentHandbook})`}} />
                <Link to={"https://zalo.me/4423607764192336885"} target="_blank" className="bg-cover bg-center bg-no-repeat aspect-[608/265]" style={{backgroundImage: `url(${fragmentSupport})`}} />
            </div>
        </div>
    )
}

export default Section2